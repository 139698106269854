<template>
  <div class="tags h-full flex flex-wrap items-center justify-center">
    <div class="flex flex-wrap p-base">
      <div
        v-for="tag in tags"
        :key="tag.id"
        class="tag-card m-sm p-sm w-64 bg-milk cursor-pointer rounded"
        @click="openEditor(tag)">
        <h2
          class="name truncate">
          {{tag.title}}
        </h2>
      </div>
    </div>
    <template v-if="!tags.length">
      <div v-if="processing" class="text-aba-blue">Loading...</div>
      <div v-else>Nothing there</div>
    </template>
  </div>
</template>

<script>
import { db } from '@/lib/firebase'
import { mapActions } from 'vuex'

export default {
  name: 'Tags',
  data: () => ({
    feed: {},
    collectionName: 'tags',
    unsubscribe: null,
    processing: false
  }),

  computed: {
    tags () {
      return Object.values(this.feed)
    }
  },

  created () {
    this.subscribeTags()
  },

  beforeDestroy () {
    if (typeof this.unsubscribe === 'function') {
      this.unsubscribe()
    }
  },

  methods: {
    ...mapActions(['showEditor']),
    openEditor (value) {
      this.showEditor({ type: 'tag', value })
    },
    subscribeTags () {
      if (typeof this.unsubscribe === 'function') return
      this.unsubscribe = db.collection('tags').onSnapshot({
        next: querySnapshot => {
          querySnapshot.docChanges().forEach(docChange => {
            const doc = docChange.doc
            switch (docChange.type) {
              case 'added':
              case 'modified':
                this.$set(this.feed, doc.id, { ...doc.data(), id: doc.id })
                break
              case 'removed':
                this.$delete(this.feed, doc.id)
            }
          })
          this.processing = false
        },
        error: err => {
          console.error('Subscribe To Tags:', err)
          this.processing = false
        }
      })
    }
  }
}
</script>

<style lang='scss'>
  .tags{
  position: relative;
  }
</style>
