<template>
  <div>
    <header class="flex items-center m-5">
      <router-link :to="{name: 'home'}">Home</router-link>
      <h1 class="flex-grow w-full mx-base capitalize">{{title}}</h1>
    </header>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'AdminArea',
  components: {
  },

  props: {
  },

  data: () => ({
  }),

  computed: {
    title () {
      return this.$route.name
    }
  },

  methods: {
  }
}
</script>

<style lang='scss'>
.admin-area {
  position: relative;
}
</style>
